import {Button} from '@material-ui/core';
import Head from 'next/head';
import {useRouter} from 'next/router';
import React, {useEffect, useState} from 'react';
import {Nl2br} from '@presentation/components/format/string/nl2br/Nl2br';
import {AbstractError} from '@errors/AbstractError';
import {NextPageContextError} from '@errors/NextPageContextError';
import {Extras} from '@sentry/types/dist/extra';
import {clearSignUpStorageItems} from '@presentation/components/auth/AuthState';
import * as Sentry from '@sentry/react';
import commonStyles from '@presentation/components/common/cssModules/common.module.scss';
import styles from './ErrorScreen.module.scss';
import clsx from 'clsx';
import OnlyLogoHeader from '../../header/onlyLogoHeader/OnlyLogoHeader';
import OnlyCopyRightFooter from '@presentation/components/footer/onlyCopyRightFooter/OnlyCopyRightFooter';
import Loading from '@presentation/components/loading/Loading';

type Props = {
  title: string;
  message: string | JSX.Element;
  error?: AbstractError | NextPageContextError;
  reloadable?: boolean;
};

const ErrorScreen = ({
  title,
  message,
  error,
  reloadable = true,
}: Props): JSX.Element => {
  const router = useRouter();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!error) return;

    let extra: Extras = {};
    if ('data' in error) {
      const _error = error as AbstractError;
      if (_error.data) {
        extra = _error.data;
      }
    }
    Sentry.captureException(error, {extra});

    if (process.env.NEXT_PUBLIC_ENV !== 'production' && process.browser) {
      try {
        // eslint-disable-next-line no-console
        console.error({
          errorMessage: error.message,
          detail: {...error},
        });
      } catch (e) {
        console.error(e);
      }
    }
  }, []);

  return (
    <>
      {isLoading && <Loading />}
      <Head>
        <title>{title}</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <meta name="robots" content="noindex, nofollow" />
      </Head>
      <div className={styles.outline}>
        <OnlyLogoHeader />
        <div className={clsx(styles.outlineContents, styles.error)}>
          <p className={styles.errorMessage}>{title}</p>
          {typeof message === 'string' ? (
            <p className={styles.errorLead}>
              <Nl2br value={message} />
            </p>
          ) : (
            {message}
          )}
          {reloadable ? (
            <>
              <div className={clsx(commonStyles.button, styles.button)}>
                <Button
                  type="button"
                  fullWidth
                  className={commonStyles.buttonGo}
                  onClick={async (e) => {
                    e.preventDefault();
                    clearSignUpStorageItems();
                    await router.reload();
                  }}
                >
                  画面を再読み込み
                </Button>
              </div>
              <div className={clsx(commonStyles.link, styles.link)}>
                <a
                  className={commonStyles.linkBack}
                  onClick={async (e) => {
                    e.preventDefault();
                    clearSignUpStorageItems();
                    await router.push('/');
                  }}
                >
                  ホームへ
                </a>
              </div>
            </>
          ) : (
            <div className={clsx(commonStyles.button, styles.button)}>
              <Button
                type="button"
                fullWidth
                className={commonStyles.buttonGo}
                onClick={async (e) => {
                  e.preventDefault();
                  setIsLoading(true);
                  clearSignUpStorageItems();
                  await router.push('/');
                }}
              >
                ホームへ
              </Button>
            </div>
          )}
        </div>
        <OnlyCopyRightFooter enableCommonStyle={true} />
      </div>
    </>
  );
};

export default ErrorScreen;
