import Head from 'next/head';
import React from 'react';
import {baseTitle} from '@constants/seo/baseTitle';

export interface SeoProps {
  description: string;
  pathName: string;
  title?: string;
  keyword?: string;
  ogImage?: string;
}

export const makeTitle = (title?: string): string => {
  return title ? `${title}｜${baseTitle}` : baseTitle;
};

export const SeoTags = ({
  params: {title, description, pathName, keyword, ogImage},
}: {
  params: SeoProps;
}): JSX.Element => {
  const formattedTitle = makeTitle(title);
  const host = process.env.NEXT_PUBLIC_HOST_NAME;
  return (
    <Head>
      <title>{formattedTitle}</title>
      <meta property="description" content={description} />
      {keyword && <meta property="keyword" content={keyword} />}
      <meta property="og:title" content={formattedTitle} />
      <meta property="og:description" content={description} />
      <meta property="og:locale" content="ja_JP" />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={`${host}${pathName}`} />
      <meta property="og:site_name" content="タダオキ" />
      <meta
        property="og:image"
        content={ogImage ?? `${host}/images/common/ogp.png`}
      />
    </Head>
  );
};
