import { useRouter } from "next/router";
import { useEffect } from "react";
import { useScreenState } from "@presentation/components/screen/screenStateHandler/screenState";
export var useRouterChange = function() {
    var ref = useScreenState(), showLoading = ref.showLoading, hideLoading = ref.hideLoading;
    var router = useRouter();
    useEffect(function() {
        var handleLoadingStart = function() {
            showLoading();
        };
        var handleLoadingStop = function() {
            hideLoading();
        };
        router.events.on("routeChangeStart", handleLoadingStart);
        router.events.on("routeChangeComplete", handleLoadingStop);
        router.events.on("routeChangeError", handleLoadingStop);
        return function() {
            router.events.off("routeChangeStart", handleLoadingStart);
            router.events.off("routeChangeComplete", handleLoadingStop);
            router.events.off("routeChangeError", handleLoadingStop);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        router
    ]);
};
