import ErrorScreen from '../errorScreen/ErrorScreen';
import Loading from '@presentation/components/loading/Loading';
import React from 'react';
import {useScreenState} from '@presentation/components/screen/screenStateHandler/screenState';
import {useRouterChange} from '@presentation/hooks/router/useRouterChange';

interface Props {
  children: React.ReactNode;
}

const ScreenStateHandler = ({children}: Props): JSX.Element => {
  const {screenState} = useScreenState();
  useRouterChange();

  if (screenState.error != null) {
    return (
      <ErrorScreen
        title="ページが表示できません"
        message={screenState.error.displayMessage}
        error={screenState.error}
      />
    );
  }
  return (
    <>
      {screenState.isLoading && <Loading />}
      {children}
    </>
  );
};
export default ScreenStateHandler;
