import React from 'react';
import Image from 'next/image';
import styles from './OnlyLogoHeader.module.scss';
import Link from 'next/link'

const OnlyLogoHeader = () => {
  return (
    <header className={styles.header}>
      <h1>
        <Link href="/">
          <Image
            src="/images/lp/logo_yoko.svg"
            width="150"
            height="44"
            alt="タダオキ"
          />
        </Link>
      </h1>
    </header>
  );
};

export default OnlyLogoHeader;
