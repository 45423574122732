import * as yup from "yup";
export var ja_JP = {
    mixed: {
        default: "${path}は有効な値を入力してください",
        required: "${path}を入力してください",
        notType: "${path}は有効な値を入力してください",
        oneOf: "${path}は次の値のいずれかを入力してください:${values}",
        notOneOf: "${path}は次の値以外のものを入力してください:${values}"
    },
    string: {
        default: "${path}は有効な値を入力してください",
        required: "${path}を入力してください",
        notType: "${path}は有効な値を入力してください",
        length: "${path}は${length}文字を入力してください",
        min: "${path}は${min}文字以上で入力してください",
        max: "${path}は${max}文字以下で入力してください",
        matches: '${path}は次と一致する値を入力してください: "${regex}"',
        email: "${path}は有効なメールアドレスを入力してください",
        url: "${path}は有効なURLを入力してください",
        trim: "${path}は前後の空白を取り除いてください",
        lowercase: "${path}は小文字のみ入力してください",
        uppercase: "${path}は大文字のみ入力してください"
    },
    number: {
        default: "${path}は有効な値を入力してください",
        required: "${path}を入力してください",
        notType: "${path}は有効な値を入力してください",
        min: "${path}は${min}以上で入力してください",
        max: "${path}は${max}以下で入力してください",
        lessThan: "${path}は${less}より小さい値を入力してください",
        moreThan: "${path}は${more}より大きい値を入力してください",
        notEqual: "${path}は${notEqual}以外の値を入力してください",
        positive: "${path}は正の数を入力してください",
        negative: "${path}は負の数を入力してください",
        integer: "${path}は整数を入力してください"
    },
    date: {
        default: "${path}は有効な値を入力してください",
        required: "${path}を入力してください",
        notType: "${path}は有効な値を入力してください",
        min: "${path}は${min}以降の日付を入力してください",
        max: "${path}は${max}以前の日付をしてください"
    },
    object: {
        default: "${path}は有効な値を入力してください",
        required: "${path}を入力してください",
        noUnknown: "${path}は登録されていないキーは入力できません"
    },
    array: {
        default: "${path}は有効な値を入力してください",
        required: "${path}を入力してください",
        notType: "${path}は有効な値を入力してください",
        min: "${path}は${min}つ以上入力してください",
        max: "${path}は${max}つ以下入力してください"
    }
};
// import する度に setLocale する必要があるため
// ここでロケール設定済みオブジェクトを export しておく
yup.setLocale(ja_JP);
export var y = yup;
